import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Loadable from 'react-loadable';
import { useInView } from 'react-intersection-observer';
import { decode } from 'shopify-gid';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../styles/index';
import { SettingsContext } from '../contexts';
import SEO from '../components/shared/SEO';
import ProductMainDesktop from '../components/product/ProductMainDesktop';
import ProductMainMobile from '../components/product/ProductMainMobile';
import { useCustomer, useGlobalComponentsQuery, useProtectionProduct } from '../hooks/index';

const ProductMediaSection = Loadable({
  loader: () => import('../components/product/ProductMediaSection'),
  loading() {
    return <div />;
  },
});

const ProductCollectionSection = Loadable({
  loader: () => import('../components/product/ProductCollectionSection'),
  loading() {
    return <div />;
  },
});

const ProductDetailsSection = Loadable({
  loader: () => import('../components/product/ProductDetailsSection'),
  loading() {
    return <div />;
  },
});

const ProductYouMayLikeSection = Loadable({
  loader: () => import('../components/product/ProductYouMayLikeSection'),
  loading() {
    return <div />;
  },
});

const ProductCollectionDisplaySection = Loadable({
  loader: () => import('../components/product/ProductCollectionDisplaySection'),
  loading() {
    return <div />;
  },
});

const ProductReviews = Loadable({
  loader: () => import('../components/product/ProductReviews'),
  loading() {
    return <div />;
  },
});

export interface productProps {
  data: {
    contentfulProduct: {
      node_locale: string;
      titleToShow: string;
      slug: string;
      seoTitle: string;
      oversell: boolean;
      allowAddProtection: boolean;
      seoDescription: string;
      productTag: string;
      shopifyData: {
        variants: {
          price: string;
          compare_at_price: string;
          title: string;
          id: string;
          product_id: string;
          sku: string;
        };
      };
      productCategory: {
        category: string;
        subcategory: string;
      };
      productImages: {
        title: string;
        fluid: string;
      };
      mobileProductImages: {
        title: string;
        fluid: string;
      };
      thumbnailsSlider: {
        title: string;
        fluid: string;
      };
      description: {
        json: string;
      };
      specs: {
        json: string;
      };
      materials: {
        json: string;
      };
      mediaSection: {
        title: string;
        subtitle: string;
        paragraph: string;
        video: any;
        image: string;
      };
      detailsImage: {
        fluid: string;
      };
      featuredCollectionSection: {
        title: string;
        slug: string;
        subtitle: string;
        items: {
          imagesSlider: {
            fluid: string;
          };
        };
      };
      detailsSection: {
        title: string;
        detailsList: [];
        dimensionsList: [];
      };
      collectionDisplaySection: {
        slug: string;
        title: string;
        heroImage: {
          fluid: string;
        };
      };
    };
  };
}

const Product = ({
  data: {
    contentfulProduct: {
      node_locale,
      titleToShow,
      slug,
      seoTitle,
      oversell,
      fastShipping,
      seoDescription,
      shopifyData,
      productCategory,
      productTag,
      productImages,
      mobileProductImages,
      thumbnailProductImages,
      variantsImages,
      mobileVariantsImages,
      thumbnailVariantsImages,
      description,
      specs,
      materials,
      mediaSection,
      detailsImage,
      featuredCollectionSection,
      detailsSection,
      collectionDisplaySection,
      protection,
    },
  },
  pageContext: { randomYouMayLike, collectionSliderArray, allLocaleSlugs },
}) => {
  const global = useGlobalComponentsQuery();
  const user = useCustomer();
  const protectionProduct = useProtectionProduct(protection);
  const allowAddProtection = !!protectionProduct;

  // ------- LazyLoading business --------------------------------------

  const [pageMid, pageMidInView] = useInView({ threshold: 0 });
  const [trigger, triggerInView] = useInView({ threshold: 0 });

  const {
    renderProduct,
    setRenderProduct,
  }: {
    renderProduct: any;
    setRenderProduct: any;
  } = useContext(SettingsContext);

  useEffect(() => {
    if (renderProduct) {
    } else if (pageMidInView || triggerInView) setRenderProduct(true);
  }, [pageMidInView, triggerInView]);

  // ------- Breakpoints business --------------------------------------

  const [isMobile, setIsMobile] = useState(true);

  const addViewedProduct = async (email: string) => {
    const data = {
      token: process.env.KLAVIYO_PRIVATE_API_KEY,
      email: email,
      productName: titleToShow,
      productID: decode(shopifyData.variants[0].product_id).id,
    };

    try {
      const response = await fetch('/.netlify/functions/track-product', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Product viewed event successfully sent to Klaviyo');
      } else {
        console.log('Failed to send event to Klaviyo', result);
      }
    } catch (error) {
      console.error('Error sending event to Klaviyo', error);
    }
  };

  useLayoutEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) setIsMobile(false);
  }, []);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true);
        return;
      }
      setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // ------- Variants business --------------------------------------

  const [selectedVariant, setSelectedVariant] = useState({} as any);
  const [allShopifyVariants, setAllShopifyVariants] = useState(false);
  const [starRating, setStarRating] = useState(0);
  const reviewsRef = useRef(null);

  useEffect(() => {
    setSelectedVariant(shopifyData.variants[0]);
  }, []);

  const setSelectedVariantState = state => setSelectedVariant(state);
  const scrollToReviews = () => {
    setRenderProduct(true);
    setTimeout(() => {
      reviewsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  // We need to get the shopify product by its En handle
  const productQuery = () => `
    query {
      products(first:10, query:"${shopifyData?.variants[0]?.sku}") {
        edges {
          node {
            id
            title
            variants(first:10) {
              edges {
                node {
                  sku
                  id
                  title
                  quantityAvailable
                }
              }
            }
          }
        }
      }
    }
  `;

  const GRAPHQL_URL = `https://${process.env.SHOPIFY_STORE}/api/2020-07/graphql.json`;

  const GRAPHQL_BODY = () => ({
    async: true,
    crossDomain: true,
    method: 'POST',
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_ACCES_TOKEN,
      'Content-Type': 'application/graphql',
    },
    body: productQuery(),
  });

  useEffect(() => {
    if (!allShopifyVariants) {
      fetch(GRAPHQL_URL, GRAPHQL_BODY())
        .then(res => res.json())
        .then(product => {
          setAllShopifyVariants(product.data.products.edges[0].node.variants.edges);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else if (!('outOfStock' in selectedVariant)) {
      setSelectedVariant({
        ...selectedVariant,
        outOfStock: !(
          allShopifyVariants.find(variant => variant?.node?.sku === selectedVariant?.sku)?.node
            .quantityAvailable > 0
        ),
        quantityAvailable: allShopifyVariants.find(
          variant => variant?.node?.sku === selectedVariant.sku
        ).node.quantityAvailable,
      });
    }
  }, [allShopifyVariants, selectedVariant]);

  async function getReviews() {
    const requestOptions = {
      method: 'GET',
    };
    const res = await fetch(
      `https://stamped.io/api/widget/reviews?productId=${
        decode(shopifyData.variants[0].product_id).id
      }&productType&email&isWithPhotos&minRating&take&page&dateFrom&dateTo&sortReviews&tags&storeUrl=d-bodhi-dev.myshopify.com&apiKey=pubkey-9Fir7iT1X7rn86rVW5DkUz8so27ohh`,
      requestOptions
    );
    return res;
  }

  useEffect(() => {
    getReviews()
      .then(res => res.json())
      .then(res => {
        if (res.data.length > 0) {
          setStarRating(res.rating);
        }
      });
    if (user != null) {
      addViewedProduct(user?.email);
    }
  }, []);

  const updateVariants = () => {
    fetch(GRAPHQL_URL, GRAPHQL_BODY())
      .then(res => res.json())
      .then(product => {
        setAllShopifyVariants(product?.data?.products?.edges[0]?.node?.variants?.edges);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: seoTitle,
    image: `https:${global?.seoImage.file.url}`,
    description: seoDescription,
    sku: shopifyData.variants[0].sku,
    brand: {
      '@type': 'Brand',
      name: 'd-Bodhi',
    },
    offers: {
      '@type': 'Offer',
      availability: selectedVariant.outOfStock
        ? 'http://schema.org/OutOfStock'
        : 'http://schema.org/InStock',
      price: shopifyData.variants[0].price,
      priceCurrency: 'EUR',
      url: `https://www.dbodhi.com/${node_locale}/${slug}`,
    },
  };

  const filteredAllowAddProtection = allowAddProtection === true;

  return (
    <Wrapper>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={`https:${global?.seoImage.file.url}`}
        schema={schema}
      />
      {isMobile ? (
        <ProductMainMobile
          title={titleToShow}
          shopifyData={shopifyData}
          slug={slug}
          productImages={mobileProductImages}
          variantsImages={mobileVariantsImages}
          description={description}
          specs={specs}
          materials={materials}
          selectedVariant={selectedVariant}
          setSelectedVariantState={setSelectedVariantState}
          updateVariants={updateVariants}
          oversell={oversell}
          fastShipping={fastShipping}
          allowAddProtection={filteredAllowAddProtection}
          protectionProduct={protectionProduct}
          productCategory={productCategory}
          productTag={productTag}
          scrollToReviews={scrollToReviews}
          starRating={starRating}
          shopifyVariants={allShopifyVariants}
        />
      ) : (
        <ProductMainDesktop
          title={titleToShow}
          slug={slug}
          shopifyData={shopifyData}
          productImages={productImages}
          thumbnailProductImages={thumbnailProductImages}
          variantsImages={variantsImages}
          thumbnailVariantsImages={thumbnailVariantsImages}
          description={description}
          specs={specs}
          selectedVariant={selectedVariant}
          setSelectedVariantState={setSelectedVariantState}
          updateVariants={updateVariants}
          oversell={oversell}
          fastShipping={fastShipping}
          allowAddProtection={allowAddProtection}
          protectionProduct={protectionProduct}
          productCategory={productCategory}
          productTag={productTag}
          scrollToReviews={scrollToReviews}
          starRating={starRating}
          shopifyVariants={allShopifyVariants}
        />
      )}
      <PageMid ref={pageMid}>
        <Trigger ref={trigger} />
        {renderProduct && (
          <>
            {mediaSection && <ProductMediaSection mediaSection={mediaSection} />}
            {featuredCollectionSection && (
              <ProductCollectionSection featuredCollectionSection={collectionSliderArray} />
            )}
            <ProductDetailsSection
              detailsSection={detailsSection}
              selectedVariant={selectedVariant}
              detailsImage={detailsImage ?? productImages[0]}
            />
            <div ref={reviewsRef}>
              <ProductReviews shopifyData={shopifyData} />
            </div>
            {randomYouMayLike.length > 1 && (
              <ProductYouMayLikeSection randomYouMayLike={randomYouMayLike} />
            )}
            {collectionDisplaySection && (
              <ProductCollectionDisplaySection
                heroImage={collectionDisplaySection.heroImage}
                link={collectionDisplaySection.slug}
                isSingleHeading
                mainHeading={collectionDisplaySection.title}
              />
            )}
          </>
        )}
      </PageMid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: auto;
`;

const Trigger = styled.div`
  position: absolute;
  top: ${mobileVW(-200)};
  left: 0;
  width: 100vw;
  height: 200px;
  z-index: ${zIndex.basement};

  ${desktopBreakpoint} {
    top: ${desktopVW(-600)};
  }
`;

const PageMid = styled.div`
  min-height: 100vh;
  position: relative;
`;

export default Product;

export const ProductPageQuery = graphql`
  query Product($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      node_locale
      titleToShow
      slug
      seoTitle
      oversell
      fastShipping
      seoDescription
      protection
      shopifyData {
        variants {
          price
          compare_at_price
          title
          id
          product_id
          sku
          inventory_quantity
        }
      }
      productTag
      productCategory {
        category
        subcategory
      }
      productImages: imagesSlider {
        title
        fluid(quality: 75, sizes: "100vw") {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      mobileProductImages: imagesSlider {
        title
        fluid(maxWidth: 700, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      thumbnailProductImages: imagesSlider {
        title
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      variantsImages {
        variantTitle
        images {
          title
          fluid(quality: 75, sizes: "100vw") {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
      mobileVariantsImages: variantsImages {
        variantTitle
        images {
          title
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      thumbnailVariantsImages: variantsImages {
        variantTitle
        images {
          title
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      description {
        json
      }
      specs {
        json
      }
      materials {
        json
      }
      mediaSection {
        title
        heading
        subtitle {
          subtitle
        }
        paragraph {
          paragraph
        }
        invertedLayout
        borderlessImage
        videoVimeo
        videoTitle
        video {
          title
          file {
            url
          }
        }
      }
      detailsImage {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featuredCollectionSection {
        title
        slug
        subtitle
        items {
          imagesSlider {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      detailsSection {
        title
        detailsList
        dimensionsList
      }
      collectionDisplaySection {
        slug
        title
        heroImage {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
