import React, { useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useCartItems, useGlobalDictionaryQuery } from '../../hooks/index';
import {
  DesktopView,
  MobileView,
  colors,
  desktopBreakpoint,
  desktopVW,
  mobileVW,
  zIndex,
} from '../../styles/index';
import Icons from '../../utils/Icons';
import formatPrice from '../../utils/formatPrice';
import ProductFormSkylt from './ProductFormSkylt';

import { useAddItemToCart, useAddToCartTracking, useProductViewTracking } from '../../hooks';

interface Props {
  shopifyData: {
    price: string;
    compareAtPrice: string;
    outOfStock: boolean;
  };
  slug: any;
  skyltChecked: any;
  setSkyltChecked: any;
  selectedVariant: {
    price: string;
    title: string;
    outOfStock: string;
    compare_at_price: string;
    id: string;
  };
  setSelectedVariantState: any;
  productTitle: string;
  updateVariants: any;
  quantity: any;
  setQuantity: any;
  category: string;
  allowAddProtection: boolean;
  protectionProduct: any;
  oversell: boolean;
  delivery: 'slow' | 'quick';
}

export default function ProductForm(props: Props) {
  const {
    shopifyData,
    skyltChecked,
    setSkyltChecked,
    selectedVariant,
    setSelectedVariantState,
    productTitle,
    updateVariants,
    quantity,
    setQuantity,
    category,
    allowAddProtection,
    protectionProduct,
    slug,
    oversell,
    delivery,
  } = props;
  const cart = useCartItems();
  const {
    isCartOpen,
    setIsCartOpen,
    purchaseAllowed,
    allProductShopify,
    setMaxQuantityCurrentVariant,
    setQuantityCurrentVariant,
  }: {
    isCartOpen: any;
    setIsCartOpen: any;
    purchaseAllowed: any;
    allProductShopify: any;
    setMaxQuantityCurrentVariant: any;
    setQuantityCurrentVariant: any;
  } = useContext(SettingsContext);
  const dictionary = useGlobalDictionaryQuery();

  const [desktopSelectOpen, setdesktopSelectOpen] = useState(false);
  const [desktopSelectState, setDesktopSelectState] = useState(selectedVariant.title);
  const [lengthOption, setLengthOption] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [maxQuantityVariant, setMaxQuantityVariant] = useState(0);
  const [disableButtonQuantityPlus, setDisableButtonQuantityPlus] = useState(false);

  const selectSpring = useSpring({
    transform: desktopSelectOpen ? 'translateY(0%)' : 'translateY(-102%)',
  });

  const handleSelectVariant = e => {
    setSelectedVariantState(...shopifyData.variants.filter(({ title }) => title === e));
  };

  const handleSizeclickDesktop = sizeTitle => {
    setSelectedVariantState(...shopifyData.variants.filter(({ title }) => title === sizeTitle));
    setDesktopSelectState(sizeTitle);
    setdesktopSelectOpen(false);
  };

  const addItemToCart = useAddItemToCart();

  const handleAddToCart = async () => {
    // If skylt is selected, pass {key: "skylt", value: "true"} as customAttributes
    // customAttribtues format [{key: "foo", value: "bar"}, {key: "foo2", value: "bar2"}]
    if (allowAddProtection) {
      await addItemToCart(selectedVariant.id, quantity, [
        {
          key: `Extra protection - ${protectionProduct.title}`,
          value: skyltChecked ? 'added' : 'not added',
        },
        // {
        //   key: `statusDelivery`,
        //   value: delivery,
        // },
        // {
        //   key: `quantityAvailable`,
        //   value: maxQuantityVariant?.toString(),
        // },
      ]);
    } else {
      await addItemToCart(selectedVariant.id, quantity);
    }
    useAddToCartTracking(
      selectedVariant.id,
      `${productTitle} - ${selectedVariant.title}`,
      parseFloat(selectedVariant.price),
      location,
      quantity,
      category
    );
    if (!isCartOpen) {
      setIsCartOpen(true);
    }
  };

  useEffect(() => {
    setQuantity(1);
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedVariant.outOfStock !== null && selectedVariant.id) {
      useProductViewTracking(
        selectedVariant.id,
        `${productTitle} - ${selectedVariant.title}`,
        parseFloat(selectedVariant.price),
        location,
        category
      );
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (cartItems.length === 0 && cart.length > 0) {
      setCartItems(cart);
    }
  }, [cart]);

  useEffect(() => {
    let checkMaxProductQuantity = '';
    if (allProductShopify?.length > 0) {
      for (let i = 0; i < allProductShopify.length; i++) {
        allProductShopify[i].node.variants.edges.map(item => {
          if (selectedVariant.sku === item.node.sku) {
            checkMaxProductQuantity = item;
          }
        });
      }
    }
    if (checkMaxProductQuantity != '') {
      setMaxQuantityVariant(checkMaxProductQuantity?.node?.quantityAvailable);
      setMaxQuantityCurrentVariant(checkMaxProductQuantity?.node?.quantityAvailable);
    }
  }, [allProductShopify, selectedVariant]);

  useEffect(() => {
    let checkTotalQuantity = [];
    checkTotalQuantity = cartItems.filter(item => item?.variant?.sku === selectedVariant?.sku);
    if (checkTotalQuantity.length > 0) {
      setTotalQuantity(checkTotalQuantity[0].quantity + quantity);
      setQuantityCurrentVariant(checkTotalQuantity[0].quantity + quantity);
    } else {
      setTotalQuantity(quantity);
      setQuantityCurrentVariant(quantity);
    }
  }, [cartItems, selectedVariant, quantity]);

  return (
    <Wrapper>
      <MobileView>
        <SizeSelect style={{ height: lengthOption > 28 ? mobileVW(60) : 'auto' }}>
          <label htmlFor="sizes">{dictionary?.pdpSizeText}</label>
          <BoxOptionDesktop>
            {shopifyData.variants.map(({ title }) => {
              if (title.length > lengthOption) {
                setLengthOption(title.length);
              }
              return (
                <OptionContent>
                  {selectedVariant.title == title ? (
                    <OptionChildMobileSelected
                      onClick={() => handleSelectVariant(title)}
                      key={title}
                    >
                      {title}
                    </OptionChildMobileSelected>
                  ) : (
                    <OptionChildMobile onClick={() => handleSelectVariant(title)} key={title}>
                      {title}
                    </OptionChildMobile>
                  )}
                </OptionContent>
              );
            })}
          </BoxOptionDesktop>
        </SizeSelect>
      </MobileView>
      <DesktopView>
        <SizeSelect onMouseLeave={() => setTimeout(() => setdesktopSelectOpen(false), 150)}>
          <label htmlFor="sizes">{dictionary?.pdpSizeText}</label>
          <BoxOptionDesktop>
            {shopifyData.variants.map(({ title }) => (
              <OptionContent>
                {title == desktopSelectState ? (
                  <OptionChildDesktopSelected
                    key={title}
                    onClick={() => handleSizeclickDesktop(title)}
                  >
                    <p>{title}</p>
                  </OptionChildDesktopSelected>
                ) : (
                  <OptionChildDesktop key={title} onClick={() => handleSizeclickDesktop(title)}>
                    <p>{title}</p>
                  </OptionChildDesktop>
                )}
              </OptionContent>
            ))}
          </BoxOptionDesktop>
        </SizeSelect>
      </DesktopView>
      <AddToCartWrapper allowAddProtection={allowAddProtection}>
        {allowAddProtection && (
          <ProductFormSkylt
            skyltChecked={skyltChecked}
            setSkyltChecked={setSkyltChecked}
            price={Number(protectionProduct?.price?.amount)}
          />
        )}
        <QuantityBlock>{dictionary?.pdpQuantityText}</QuantityBlock>
        <QuantityCounterBlock>
          <Counter onMouseEnter={() => updateVariants()}>
            <Button
              type="button"
              aria-label="decrease quantity"
              onClick={() => (quantity > 1 ? setQuantity(quantity - 1) : null)}
            >
              -
            </Button>
            <Count>{quantity}</Count>
            <Button
              type="button"
              disabled={!!(totalQuantity >= maxQuantityVariant && oversell === false)}
              aria-label="increase quantity"
              onClick={() => setQuantity(quantity + 1)}
            >
              +
            </Button>
          </Counter>
        </QuantityCounterBlock>
        <AddToCartBlock>
          {purchaseAllowed &&
            (purchaseAllowed?.allowed ? (
              <button type="button" aria-label="add to cart" onClick={() => handleAddToCart()}>
                {`${formatPrice(
                  selectedVariant.price && skyltChecked
                    ? selectedVariant.price * quantity +
                        Number(protectionProduct.price.amount) * quantity
                    : selectedVariant.price
                    ? selectedVariant.price * quantity
                    : 0,
                  '€'
                )}
                   - ${dictionary?.addToCartText}`}
              </button>
            ) : (
              <DeniedMsg>{dictionary?.noPurchaseMessage}</DeniedMsg>
            ))}
        </AddToCartBlock>
      </AddToCartWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SizeSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border-bottom: ${mobileVW(1)} solid ${colors.lightGrey};
  padding-bottom: ${mobileVW(10)};
  margin-bottom: ${mobileVW(30)};
  position: relative;
  z-index: 2;

  label {
    font-size: ${mobileVW(18)};
    margin-bottom: ${mobileVW(10)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
      margin-bottom: ${desktopVW(10)};
    }
  }

  ${desktopBreakpoint} {
    border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};
    padding-bottom: ${desktopVW(10)};
    // padding-right: ${desktopVW(25)};
    margin-bottom: ${desktopVW(20)};
    font-size: ${desktopVW(14)};
  }
`;

const AddToCartWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: ${props =>
    props.allowAddProtection
      ? `"skyltBlock skyltBlock" "quantity quantityCounter" "addtoCart addtoCart"`
      : `"quantity quantityCounter" "addtoCart addtoCart"`};
  grid-template-columns: 40% 60%;
  grid-template-rows: ${props =>
    props.allowAddProtection
      ? `${mobileVW(73)} ${mobileVW(90)} ${mobileVW(90)}`
      : `${mobileVW(90)} ${mobileVW(90)}`};
  ${desktopBreakpoint} {
    grid-template-rows: ${props =>
      props.allowAddProtection
        ? `${desktopVW(53)} ${desktopVW(74)} ${desktopVW(74)}`
        : `${desktopVW(74)} ${desktopVW(74)}`};
  }
`;

const VariantSelect = styled.select`
  color: ${colors.black};
  width: auto;
  position: absolute;
  top: 0;
  right: ${mobileVW(30)};
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border: none;
  //outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent !important;

  option {
    text-align: right;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(160)};
    margin-left: ${desktopVW(10)};
    font-size: ${desktopVW(14)};
    cursor: pointer;
  }
`;

const VariantSelectHeaderDesktop = styled.div`
  color: ${colors.black};
  width: auto;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border: none;
  font-size: ${desktopVW(14)};
  cursor: pointer;
`;

const SelectOptionsDesktop = styled(animated.div)`
  position: absolute;
  top: ${desktopVW(27.5)};
  right: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  overflow: hidden;
`;
const OptionChildMobile = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${mobileVW(45)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${mobileVW(10)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  font-size: ${mobileVW(14)};
  border-bottom: ${mobileVW(1)} solid ${colors.lightGrey};
  width: ${mobileVW(175)};
  p {
    pointer-events: none;
  }
`;

const OptionChildMobileSelected = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${mobileVW(45)};
  font-size: ${mobileVW(14)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${mobileVW(10)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border: ${desktopVW(3)} solid #262525;
  width: ${mobileVW(175)};
  p {
    pointer-events: none;
  }
`;

const OptionChildDesktop = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${desktopVW(40)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(5)};
  width: ${desktopVW(188)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};
  p {
    pointer-events: none;
  }
`;

const OptionChildDesktopSelected = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${desktopVW(40)};
  display: flex;
  width: ${desktopVW(188)};
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(5)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border: ${desktopVW(2)} solid #262525;
  p {
    pointer-events: none;
  }
`;

const BoxOptionDesktop = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OptionContent = styled.div`
  margin-bottom: ${desktopVW(5)};
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${mobileVW(4)};

  ${desktopBreakpoint} {
    right: ${desktopVW(-5)};
    padding-top: 0;
    cursor: pointer;
  }
`;

const QuantityBlock = styled.div`
  grid-area: quantity;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-left: ${mobileVW(1)} solid ${colors.lightGrey};
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    border-left: ${desktopVW(1)} solid ${colors.lightGrey};
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
  }
`;

const QuantityCounterBlock = styled.div`
  grid-area: quantityCounter;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-left: ${mobileVW(1)} solid ${colors.lightGrey};
  border-right: ${mobileVW(1)} solid ${colors.lightGrey};
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    border-left: ${desktopVW(1)} solid ${colors.lightGrey};
    border-right: ${desktopVW(1)} solid ${colors.lightGrey};
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
    font-size: ${desktopVW(14)};
  }
`;

const AddToCartBlock = styled.div`
  grid-area: addtoCart;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  font-size: ${mobileVW(18)};

  button {
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    color: ${colors.cappuccino};
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const Counter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 40%;
  height: 100%;
  cursor: pointer;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${desktopBreakpoint} {
    width: 10%;
    height: 100%;
  }
`;

const DeniedMsg = styled.div`
  width: 100%;
  height: 100%;
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};
  font-size: ${mobileVW(18)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 ${mobileVW(20)};

  ${desktopBreakpoint} {
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
    font-size: ${desktopVW(14)};
    padding: 0 ${desktopVW(20)};
  }
`;
