import React from 'react';
import styled from 'styled-components';
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../styles/index';
import { useGlobalDictionaryQuery } from '../../hooks/index';

interface Props {
  skyltChecked: boolean;
  setSkyltChecked: any;
  price: number;
}

export default function ProductFormSkylt(props: Props) {
  const { skyltChecked, setSkyltChecked, price } = props;

  const dictionary = useGlobalDictionaryQuery();

  return (
    <SkyltBlock>
      <SkyltLeft>
        <SkyltLabel onClick={() => setSkyltChecked(skyltChecked => !skyltChecked)}>
          <SkyltCheckbox checked={skyltChecked} />
          <span>{dictionary?.addProtectionText}</span>
        </SkyltLabel>
        <SkyltTooltipTrigger>
          ?<SkyltTooltip>{dictionary?.addProtectionDescription}</SkyltTooltip>
        </SkyltTooltipTrigger>
      </SkyltLeft>
      <SkyltRight>+€{price}</SkyltRight>
    </SkyltBlock>
  );
}

const SkyltBlock = styled.div`
  grid-area: skyltBlock;
  font-family: 'messinaMono';
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${mobileVW(16)};
  font-size: ${mobileVW(18)};
  border: ${mobileVW(1)} solid ${colors.lightGrey};
  border-bottom: none;

  ${desktopBreakpoint} {
    border: ${desktopVW(1)} solid ${colors.lightGrey};
    border-bottom: none;
    padding: 0 ${desktopVW(20)};
    font-size: ${desktopVW(14)};
  }
`;

const SkyltLeft = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const SkyltRight = styled.span`
  font-family: 'messinaMonoLight';
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(18)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const SkyltLabel = styled.label<any>`
  font-family: 'messinaMonoLight';
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${mobileVW(14)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const SkyltCheckbox = styled.div<any>`
  background: ${props => (props.checked ? 'black' : 'none')};
  border: ${mobileVW(1)} solid black;
  height: ${mobileVW(14)};
  width: ${mobileVW(14)};
  margin-right: ${mobileVW(16)};
  ${desktopBreakpoint} {
    border-width: ${desktopVW(1)};
    height: ${desktopVW(10)};
    width: ${desktopVW(10)};
    margin-right: ${desktopVW(12)};
  }
`;

const SkyltTooltip = styled.span`
  position: absolute;
  background: #efedea;
  visibility: hidden;
  cursor: default;
  font-size: ${mobileVW(13)};
  border: ${mobileVW(1)} solid black;
  bottom: ${mobileVW(11)};
  left: ${mobileVW(11)};
  width: ${mobileVW(270)};
  padding: ${mobileVW(10)};
  transform: translateX(-71%);
  ${desktopBreakpoint} {
    font-size: ${desktopVW(12)};
    border-width: ${desktopVW(1)};
    bottom: ${desktopVW(11)};
    left: ${desktopVW(11)};
    width: ${desktopVW(270)};
    padding: ${desktopVW(10)};
    transform: translateX(-50%);
  }
`;

const SkyltTooltipTrigger = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: ${mobileVW(1)} solid black;
  margin-left: ${mobileVW(8)};
  height: ${mobileVW(22)};
  width: ${mobileVW(22)};
  z-index: 1;
  ${desktopBreakpoint} {
    border-width: ${desktopVW(1)};
    margin-left: ${desktopVW(8)};
    height: ${desktopVW(22)};
    width: ${desktopVW(22)};
  }
  &:hover ${SkyltTooltip} {
    visibility: visible;
  }
`;
