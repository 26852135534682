import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, mobileContentSidePadding, zIndex } from '../../styles/index'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import paper from '../../../static/paperBackground/paper.jpeg'
import Freckles from '../shared/Freckles'
import Img from 'gatsby-image'
import ProductFormSection from './ProductFormSection'


interface Props {
  title: string
  oversell: boolean
  fastShipping: boolean
  allowAddProtection: boolean
  shopifyData: {
    price: string
    compareAtPrice: string
    outOfStock: boolean
  }
  productImages: [{
    title: string
    fluid: string
  }]
  variantsImages: [{
    variantTitle: string
    images: [{
      title: string
      fluid: string
    }]
  }]
  productCategory: {
    category: string
    subcategory: string
  }
  description: {
    json: string
  }
  specs: {
    json: string
  }
  materials: {
    json: string
  }
  selectedVariant: {
    price: string
    title: string
    outOfStock: string
    compare_at_price: string
  }
  setSelectedVariantState: any
  updateVariants: any
  productTag: string
  protectionProduct: any
  scrollToReviews: any
  starRating: number
}

export default function ProductMainMobile(props: Props) {

  const {
    title,
    oversell,
    fastShipping,
    allowAddProtection,
    shopifyData,
    productImages,
    variantsImages,
    productCategory,
    description,
    specs,
    materials,
    selectedVariant,
    setSelectedVariantState,
    updateVariants,
    productTag,
    protectionProduct,
    scrollToReviews,
    starRating,
    shopifyVariants={shopifyVariants}
  } = props;

  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: false,
    },
    shouldSwiperUpdate: true
  }

  const selectedVariantImages = variantsImages?.find(images => images.variantTitle === selectedVariant.title)

  const allImages = selectedVariantImages?.images 
    ? [...selectedVariantImages.images, ...productImages]
    : productImages

  return (
    <Wrapper>
      <FrecklesWrapper>
        <Freckles 
          canvasHeight={'150vh'}
        />
      </FrecklesWrapper>
      <SwiperWrapper className='product-slider-wrapper'>
      <Swiper {...params}>
        {
          allImages.map(({title, fluid}, index)=>(
            <div className='swiper-slide' key={title}>
              <SwiperSlide>
                <ImageWrapper>
                    <StyledImg 
                      fluid={fluid}
                    />
                  </ImageWrapper>
              </SwiperSlide>
            </div>
          ))
        }
      </Swiper>
      </SwiperWrapper>
      <ProductFormWrapper>
        <ProductFormSection
          shopifyData={shopifyData}
          title={title}
          description={description}
          specs={specs}
          materials={materials}
          selectedVariant={selectedVariant}
          setSelectedVariantState={setSelectedVariantState}
          oversell={oversell}
          fastShipping={fastShipping}
          allowAddProtection={allowAddProtection}
          updateVariants={updateVariants}
          productCategory={productCategory}
          productTag={productTag}
          protectionProduct={protectionProduct}
          scrollToReviews={scrollToReviews}
          starRating={starRating}
          shopifyVariants={shopifyVariants}
        />
      </ProductFormWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${paper});
  background-repeat: repeat;
`;

const SwiperWrapper = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
  z-index: ${zIndex.surface};

  .swiper-container {
    width: 100%;
  }
  .swiper-wrapper {
    //align-items: left;
  }

  .swiper-pagination{
    margin-bottom: ${mobileVW(20)};
    position: absolute;
    top: ${mobileVW(410)};
    left: 50vw;
    transform: translateX(-50%);
  }
  .swiper-pagination-bullet{
    background-color: black;
    transform: scale(1);
    opacity: 1;
  }

  .swiper-pagination-bullet-active{
    transform: scale(1.3);

  }
`

const SwiperSlide = styled.div`
  //min-height: ${mobileVW(1200)};
  height: auto;


`;

const FrecklesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.dots};

`;

const ImageWrapper = styled.div`
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: ${mobileVW(450)};
  z-index: ${zIndex.backgrounds};
`;

const StyledImg = styled(Img)`
  width: 100vw;
`;

const ProductFormWrapper = styled.div`
  /* position: absolute;
  top: ${mobileVW(450)};
  left: 0; */
  width: auto;
  z-index: ${zIndex.surface};
  padding: 0 ${mobileContentSidePadding};

`;